import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { AuthHeader, AuthHeaderMobile } from "@/components/AuthHeader";
import { DesktopFooter } from "@/components/DesktopFooter";
import { LoginForm } from "@/components/Forms/AuthForm";
import { CloseUnfilled } from "@/components/Icons";
import { Loader } from "@/components/Loader";
import { useUser } from "@/lib/auth/useUser";
import { stringParam } from "@/utils/queryparams";

const Page = (): JSX.Element => {
    const { t, ready } = useTranslation("common");
    const router = useRouter();
    const user = useUser();

    const redirect = stringParam(router.query.redirect);
    const isLoggedIn = !!user;
    const redirectUrl = redirect ? decodeURIComponent(redirect) : "/";

    useEffect(() => {
        if (!isLoggedIn) return;
        router.push(redirectUrl);
    }, [isLoggedIn, redirectUrl, router]);

    if (!ready)
        return (
            <div className="flex h-dvh w-full items-center justify-center bg-blue-grey-20 p-8">
                <Loader loading />
            </div>
        );

    return (
        <div className="min-h-screen bg-white lg:bg-inherit">
            <Head>
                <title>{t("logIn", "Log In")} | Courtsite</title>
            </Head>
            <AuthHeader t={t} />
            <div className="flex min-h-screen w-full justify-center lg:bg-blue-grey-20 lg:pb-8">
                <div className="h-fit w-full max-w-[1056px] bg-white lg:mt-4 lg:flex lg:flex-col lg:gap-1 lg:rounded-xl">
                    <Link href={redirectUrl} passHref>
                        <div className="hidden cursor-pointer items-center justify-end gap-2 pr-4 pt-4 text-right lg:flex">
                            <span className="typography-h5 text-blue-grey-900">
                                {t("buttonClose", "Close")}
                            </span>
                            <CloseUnfilled className="size-4" />
                        </div>
                    </Link>
                    <div className="mx-auto lg:max-w-[343px]">
                        <AuthHeaderMobile t={t} path={redirectUrl} />
                        <LoginForm className="p-4 pt-8 lg:p-0 lg:pb-10" />
                    </div>
                </div>
            </div>
            <DesktopFooter />
        </div>
    );
};
export default Page;
